<template>
  <div class="home_content">
    <div class="content_box">
      <topNav pageName="home"></topNav>
    </div>
    <div class="swiper_box">
      <swiper
        :modules="modules"
        :loop="true"
        :slides-per-view="1"
        :space-between="0"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :navigation="navigation"
        :pagination="{ clickable: true }"
        :scrollbar="{ draggable: false }"
      >
        <swiper-slide
          ><img src="/static/home/banner.png" style="width: 100%"
        /></swiper-slide>
        <swiper-slide
          ><img src="/static/home/banner.png" style="width: 100%"
        /></swiper-slide>
      </swiper>
      <!-- <img class="logo" src="/static/home/logo.png" /> -->
      <img
        class="phone wow animate__fadeInRight"
        src="/static/home/phone.png"
      />
    </div>
    <div class="content_box">
      <div class="top_box">
        <div class="top_list">
          <div>推拿</div>
          <div>理疗</div>
          <div>泰式SPA</div>
          <div>纤体养生</div>
          <div>通络</div>
          <!-- <div>足疗</div> -->
          <!-- <div>采耳</div> -->
        </div>
        <div class="img_box">
          <img
            class="wow animate__fadeInLeftBig"
            src="/static/home/img_1.png"
          />
          <img
            class="wow animate__fadeInRightBig"
            src="/static/home/img_2.png"
          />
        </div>
      </div>
    </div>
    <!-- 商家展示 -->
    <div class="shop_desc wow animate__fadeInUp">
      <div class="title">
        <div>EXCELLENT</div>
        <div>BUSINESS DISPLAY</div>
        <div>优秀商家展示</div>
      </div>
      <div class="swiper-container" ref="swiperRef">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="img_list">
              <img src="/static/home/js_3.png" />
              <img src="/static/home/js_2.png" />
              <img src="/static/home/js_1.png" />
            </div>
          </div>
          <div class="swiper-slide">
            <div class="img_list">
              <img src="/static/home/js_3.png" />
              <img src="/static/home/js_2.png" />
              <img src="/static/home/js_1.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="tool">
        <img src="/static/home/left.png" @click="handleClickBtn(0)" />
        <img src="/static/home/right.png" @click="handleClickBtn(1)" />
      </div>
    </div>
    <!-- 服务项目 -->
    <div class="project_content">
      <div class="title">SERVICE ITEMS</div>
      <div class="desc">高品质服务项目</div>
      <div class="project_list">
        <div
          class="project_list_item wow animate__fadeInUp"
          v-for="(k, i) of list"
          :key="i"
        >
          <img :src="k.img" />
          <div class="project_list_item_name">{{ k.name }}</div>
          <div class="project_list_item_text" v-if="k.part">
            <h4>项目特色：</h4>
            {{ k.part }}
          </div>
          <div class="project_list_item_text" v-if="k.provide">
            <h4> 服务内容：</h4>
            <!-- {{ k.provide }} -->
             <p v-html="k.provide"></p>
          </div>
          <div class="project_list_item_text" v-if="k.posture">
            <h4>服务用品：</h4>
            {{ k.posture }}
          </div>
          <!-- <div class="project_list_item_text" v-if="k.effect">
            作用：{{ k.effect }}
          </div> -->
          <div class="project_list_item_text" v-if="k.desc">{{ k.desc }}</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
    <el-dialog v-model="showDownLoad" width="300">
      <div class="down_box">
        <div @click="handleDownLoad">
          <img src="/static/az.png" alt="" />
          <p>安卓下载</p>
        </div>
        <div @click="handleDownLoad">
          <img src="/static/ios.png" alt="" />
          <p>IOS下载</p>
        </div>
      </div>
    </el-dialog>

    <img
      src="/static/download.png"
      alt=""
      class="download"
      @click="handleDownLoad"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import myFooter from "@/components/my-footer/index.vue";
import topNav from "@/components/nav/index.vue";
import mySwiper from "swiper";
import { WOW } from "wowjs";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";
const modules = [Autoplay, Pagination, Navigation, Scrollbar];
const list = reactive([
  {
    img: "/static/home/x_1.png",
    name: "芳香SPA+眼护",
    part: "聆听大自然秘语，结合五感六觉进入沉浸式spa之旅",
    provide: `俯卧位（45分钟）：</br>
背面放松+精油（35分钟）——后腿精油（10分钟）</br>
仰卧位（75分钟）：</br>
面部清洁——眼部拨筋按摩（25分钟）——敷眼膜、头疗：（15分钟）——胸部精油（10分钟）——眼部蒸汽—、腹部精油（10分钟）——手臂精油（5分钟）——前腿精油（10分钟）`,
    posture: "泰国进口纯植物香薰精油、香薰灯、疗养音乐、眼部按摩乳、眼膜、蒸汽眼罩、天然粉水晶玉石、一次性毛巾、床单、一次性短裤、植物按摩精油",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_2.png",
    name: "芳香SPA+热灸",
    part: "植物精油与按摩手法、能量热石相结合",
    provide: `俯卧位（55分钟）：</br>
背部+手臂（精油）（40分钟）——背部砭石温灸（5分钟）——腿部（精油）（10分钟）</br>
仰卧位（45分钟）：</br>
头部按摩（10分钟）——肩颈按摩（5分钟）——胸部（精油）（5分钟）——腹部（精油）（10分钟）——腹部砭石温灸（5分钟）——腿部（精油）（10分钟）`,
    posture: "一次性毛巾、一次性床单、能量发热砭石、一次性短裤、植物芳香精油",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_3.png",
    name: "全身芳香SPA",
    part: "植物精油与按摩手法相结合",
    provide: `俯卧位（50分钟）：</br>
背部+手臂（精油）（40分钟）——后面腿部（精油）（10分钟）</br>
俯卧位（40分钟）：</br>
头部按摩（10分钟）——肩颈按摩(5分钟）——腹部（精油）（15分钟）——正面腿部（精油）（10分钟）`,
    posture: "一次性毛巾、一次性床单、一次性短裤、植物芳香精油",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_4.png",
    name: "精油开背",
    part: "植物精油spa与中式传统按摩相结合 ",
    provide: `仰卧位（40分钟）：</br>
头部按摩（10分钟）——肩颈按摩（5分钟)——手臂按摩（5分钟）——正面腿部按摩（10分钟）——腹部（精油）（10分钟）</br>
俯卧位（40分钟）：</br>
背部（精油）（35分钟）——臀部+后面腿部按摩（5分钟）`,
    posture: "一次性毛巾、一次性床单、一次性短裤、植物芳香精油",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_5.png",
    name: "泰式古法",
    part: "中式传统按摩与泰式拉伸相结合",
    provide: `仰卧位（25分钟）：</br>
头、肩颈部按摩（10分钟）——手臂按摩（5分钟）——前腿部按摩（5分钟）——正面泰式拉伸（5分钟）</br>
俯卧位（35分钟）：</br>
背部按摩（15分钟）——臀部+后腿部按摩（5分钟）——背面泰式拉伸（10分钟）——坐姿拉伸（5分钟）`,
    posture: "",
    effect: "全身放松，缓解身体疲劳",
  },
  {
    img: "/static/home/x_6.png",
    name: "暖宫养护（限女性）",
    part: "按摩手法与大炮艾灸相结合",
    provide:`俯卧位（15分钟）：</br>
 腰骶部按摩（精油）（15分钟）</br>
仰卧位（55分钟）：</br>
腹部按摩（精油）（20分钟）——腹部（艾灸）（15分钟）——头部按摩（15分钟）——肩颈按摩（5分钟）`,
    posture: "一次性床单、一次性毛巾、一次性短裤、植物按摩精油、大炮艾柱",
    effect:
      "缓解身体疲劳，眼部抗衰，改善眼部浮肿、黑眼圈、眼角纹、眼部干涩、眼疲劳、眼光无神，眼部衰老，调理睡眠",
  },
  {
    img: "/static/home/x_8.png",
    name: "女神尊享spa（限女性）",
    part: "植物精油与按摩手法相结合",
    provide:`俯卧位（45分钟）：</br>
肩颈（精油）（15分钟）——背部（精油）（20分钟）——腿部（精油）（10分钟）</br>
仰卧位（15分钟）：</br>
腿部（精油）（10分钟）——手臂（精油）（5分钟）`,
    posture: "一次性床单、一次性毛巾、一次性短裤、植物芳香精油",
    effect:
      "缓解身体疲劳，眼部抗衰，改善眼部浮肿、黑眼圈、眼角纹、眼部干涩、眼疲劳、眼光无神，眼部衰老，调理睡眠",
  },
  {
    img: "/static/home/x_7.png",
    name: "项目回购",
    part: "",
    provide: "",
    posture: "",
    effect: "",
    desc: "订单结束30分钟内均可点击回购",
  },
]);
const swiperRef = ref(null);
const swiperExl = ref(null);
const handleClickBtn = (val) => {
  if (swiperExl.value) {
    // console.log(swiperExl.value);
    swiperExl.value.slideTo(val);
  }
};
const wow_example = new WOW({
  boxClass: "wow",
  animateClass: "animate__animated",
  offset: 0,
  mobile: true,
  live: true,
});
const showDownLoad = ref(false);
const handleDownLoad = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isiOS = ua.indexOf("android") > -1 || ua.indexOf("linux") > -1; //ios终端
  const isWeixin = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isiOS) {
    location.href =
      "https://apps.apple.com/cn/app/%E7%88%B1%E5%B0%9A%E5%BE%80%E7%BA%A6/id6470423165";
    return;
  }
  if (isWeixin) {
    this.show = true;
  } else {
    location.href = "https://obs.cqwydj.com/wydj.apk";
  }
};
onMounted(() => {
  swiperExl.value = new mySwiper(swiperRef.value, {
    slidesPerView: 1,
  });
  wow_example.init();
});
</script>
<style lang="scss">
.swiper-container {
  overflow: hidden;
}

.home_content {
  .content_box {
  }
  .download {
    position: fixed;
    bottom: 10%;
    right: 5%;
    width: 100px;
    height: 100px;
    z-index: 999;
  }
  .down_box {
    display: flex;
    justify-content: space-around;
    & > div {
      width: 80px;
      text-align: center;
    }
    & img {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      margin-bottom: 5px;
    }
  }
  .nav {
    padding-right: 149px;
  }
  .swiper_box {
    position: relative;
    .phone {
      position: absolute;
      bottom: 0px;
      right: 2%;
      width: 15%;
      z-index: 10;
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 11%;
      z-index: 10;
      transform: translateY(-100%);
    }
  }
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      background: #80a128;
      display: flex;
      align-items: center;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      & > div {
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
    }
  }

  .shop_desc {
    background: #00963a;
    position: relative;

    .title {
      font-family: DIN Light;
      font-weight: 400;
      color: #ffffff;
    }

    .img_list {
      display: flex;
      justify-content: space-between;
    }

    .tool {
      position: absolute;
      display: flex;
      justify-content: space-between;
      & > img {
        cursor: pointer;
      }
    }
  }

  .project_content {
    margin: 0 auto;
    .title {
      font-family: DIN Light;
      font-weight: 400;
      color: #606060;
    }

    .desc {
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #606060;
    }

    .project_list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      &_item {
        & > img {
          width: 100%;
        }
        &_name {
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #606060;
        }

        &_text {
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #606060;
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .carousel {
    height: 110px;
  }
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      height: 30px;
      font-size: 14px;
      justify-content: center;
      & > div {
        padding: 0 5px;
        cursor: pointer;
      }
      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }
    .img_box {
      margin-top: 5px;
      & > img:first-child {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
      & > img:last-child {
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .shop_desc {
    padding: 10px;
    .title {
      font-size: 18px;
      & > div:last-child {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    .img_list {
      margin-top: 20px;
      & > img {
        width: 100px;
      }
    }
    .tool {
      width: 50px;
      height: 20px;
      top: 50px;
      right: 20px;
      & > img {
        width: 20px;
      }
    }
  }
  .project_content {
    margin-top: 20px;
    padding: 0 20px;
    .title {
      font-size: 20px;
    }
    .desc {
      margin-top: 10px;
      font-size: 14px;
    }
    .project_list {
      margin-top: 10px;
      justify-content: space-between;
      &_item {
        width: 45%;
        margin-bottom: 44px;
        & > img {
          width: 100%;
        }
        &_name {
          font-size: 16px;
          margin-top: 10px;
        }

        &_text {
          margin-top: 10px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      height: 30px;
      font-size: 14px;
      justify-content: center;
      & > div {
        padding: 0 5px;
        cursor: pointer;
      }
      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }
    .img_box {
      margin-top: 5px;
      & > img:first-child {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
      & > img:last-child {
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .shop_desc {
    padding: 10px;
    .title {
      font-size: 18px;
      & > div:last-child {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    .img_list {
      margin-top: 20px;
      & > img {
        width: 100px;
      }
    }
    .tool {
      width: 50px;
      height: 20px;
      top: 50px;
      right: 20px;
      & > img {
        width: 20px;
      }
    }
  }
  .project_content {
    margin-top: 20px;
    padding: 0 20px;
    .title {
      font-size: 20px;
    }
    .desc {
      margin-top: 10px;
      font-size: 14px;
    }
    .project_list {
      margin-top: 10px;
      justify-content: space-between;
      &_item {
        width: 45%;
        margin-bottom: 44px;
        & > img {
          width: 100%;
        }
        &_name {
          font-size: 16px;
          margin-top: 10px;
        }

        &_text {
          margin-top: 10px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 998px) {
  .carousel {
    height: 440px;
  }
  .content_box {
    width: 1400px;
    margin: 0 auto;
  }
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      height: 42px;
      padding-left: 496px;
      font-size: 21px;
      & > div {
        padding: 0 10px;
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
      margin-top: 59px;
      padding-left: 30px;
      display: flex;
      justify-content: space-between !important;
      & > img:first-child {
        width: 400px;
      }
      & > img:last-child {
        width: 900px;
      }
    }
  }
  .shop_desc {
    width: 1400px;
    height: 852px;
    margin: 197px auto 188px auto;
    padding: 60px;
    .title {
      font-size: 43px;
      & > div:last-child {
        font-size: 29px;
        margin-top: 20px;
      }
    }

    .img_list {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      & > img {
        width: 400px;
      }
    }

    .tool {
      width: 155px;
      height: 55px;
      top: 150px;
      right: 87px;
      & > img {
        width: 55px;
      }
    }
  }
  .project_content {
    width: 1400px;
    margin: 0 auto;
    margin-top: 188px;

    .title {
      font-size: 43px;
    }

    .desc {
      margin-top: 21px;
      font-size: 29px;
    }
    .project_list {
      margin-top: 55px;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      &_item {
        width: 320px;
        margin-bottom: 44px;
        & > img {
          width: 100%;
        }
        &_name {
          font-size: 21px;
          margin-top: 15px;
        }

        &_text {
          margin-top: 10px;
          font-size: 13px;
          line-height: 33px;
        }
      }
      & > .project_list_item:not(:nth-child(4n + 1)) {
        margin-left: 20px;
      }
    }
  }
}
</style>
